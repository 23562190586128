import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React, { useRef, useState, useCallback } from 'react';
import supabase from '../lib/supabase';
import VideoPlayer from './VideoPlayer';

const PAGE_SIZE = 5; // Since we're loading one track at a time

// function useRandomSeed(playlistId: string) {
//   const [timestamp, setTimestamp] = useState(() => Date.now());

//   const generateSeed = useCallback((currentTimestamp: number) => {
//     const combinedString = `${playlistId}-${currentTimestamp}`;
    
//     let hash = 0;
//     for (let i = 0; i < combinedString.length; i++) {
//       const char = combinedString.charCodeAt(i);
//       hash = ((hash << 5) - hash) + char;
//       hash = hash & hash; // Convert to 32-bit integer
//     }
    
//     return (hash & 0x7fffffff) / 0x7fffffff;
//   }, [playlistId]);

//   const seed = useMemo(() => generateSeed(timestamp), [generateSeed, timestamp]);

//   const refreshSeed = useCallback(() => {
//     setTimestamp(Date.now());
//   }, []);

//   return { seed, refreshSeed };
// }

const InfinitePlayer = () => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [progressPct, setProgressPct] = useState<any>(0);
  const containerEl = useRef<HTMLDivElement | null>(null);
  //const { seed } = useRandomSeed(currentTrackIndex.toString());
  const seed = 0.5099401159723942;

  const fetchTrack = useCallback(async ({ pageParam = 0 }) => {
    const { data, error } = await supabase.rpc('get_recommendations_epsilon_greedy_fallback', { 
      p_user_id: 'b2a2a6f3-fd6e-42f4-a133-328083b22a20', // Default user ID if no profile
      p_limit: PAGE_SIZE,
      p_epsilon: 0.1,
      p_min_scored: 50,
      p_seed: seed,
      p_offset_val: pageParam * PAGE_SIZE 
    });

    if (error) throw error;
    return data;
  }, [seed]);

  const {
    data,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['playlist', seed],
    queryFn: fetchTrack,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.length) {
        return undefined;
      }
      return pages.length;
    },
    initialPageParam: 0,
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
  });

  const getCurrentTrack = useCallback(() => {
    if (!data?.pages) return null;
    const trackIndex = currentTrackIndex;
    let remainingIndex = trackIndex;
    
    for (const page of data.pages) {
      if (remainingIndex < page.length) {
        return page[remainingIndex];
      }
      remainingIndex -= page.length;
    }
    return null;
  }, [data?.pages, currentTrackIndex]);

  const currentTrack = getCurrentTrack();

  const nextTrack = useCallback(() => {
    const nextIndex = currentTrackIndex + 1;
    setCurrentTrackIndex(nextIndex);
    
    // If we're getting close to the end of our loaded tracks, fetch more
    const totalTracks = data?.pages.reduce((sum, page) => sum + page.length, 0) ?? 0;
    if (nextIndex >= totalTracks - 2) {
      fetchNextPage();
    }
  }, [currentTrackIndex, data?.pages, fetchNextPage]);

  const prevTrack = useCallback(() => {
    setCurrentTrackIndex(prev => (prev > 0 ? prev - 1 : prev));
  }, []);

  const { isLoading: isWaveformLoading, data: waveform } = useQuery({
    queryKey: ['waveform', { track: currentTrack }],
    queryFn: async () => {
      // @ts-ignore
      const response = await fetch(
        `https://fm-jacket-uploads.s3.us-west-2.amazonaws.com/${currentTrack.profile_id}/${currentTrack.file}.svg`,
      );
      if (!response.ok) {
        throw new Error('Error fetching waveform');
      }
      const result = await response.text();
      if (result.charAt(0) === 'I') throw new Error('Error fetching waveform');
      return result;
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
    enabled: !!currentTrack,
  });

  return (
    <div className="flex flex-col w-full relative items-center justify-center p-4">
      <div className='items-center justify-center flex flex-col gap-y-2'>
        <span className='text-white font-semibold capitalize'>Listen to Public Stories</span>
        <div className='w-36 border-b-4 border-white mb-4' />
      </div>
      <div
        ref={containerEl}
        className="rounded-2xl max-w-[290px] sm:max-w-[390px] overflow-hidden border-card-border shadow-lg border-b-[4px] bg-card-background"
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col pb-2 min-h-[186px]">
            {isLoading && <span className="p-2">Your audio feed is loading...</span>}
            {!isLoading && !currentTrack && (
              <span className="p-2 px-10">Nothing to load, the database is empty.</span>
            )}
            {!isLoading && currentTrack && (
              <div className="flex flex-row mb-5 px-2 pt-2">
                <img
                  alt="User avatar"
                  src={currentTrack.avatar}
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div className="flex flex-col mx-2 flex-1 min-w-[0]">
                  <span className="text-card-subtext truncate">
                    @{currentTrack.username}
                  </span>
                  <span className="font-semibold text-[#004080] truncate">{currentTrack.title}</span>
                </div>
              </div>
            )}
            {!isWaveformLoading && (
              <div className="w-[390px] h-[100px] relative">
                <div
                  className="absolute top-0"
                  dangerouslySetInnerHTML={{
                    __html: waveform ? waveform.replace(/stroke="[^"]*"/g, 'stroke="#999999"') : '',
                  }}
                />
                <div
                  className="absolute top-0 border-r-2 border-[#004080] overflow-hidden"
                  style={{ width: `${progressPct}%` }}
                  dangerouslySetInnerHTML={{
                    __html: waveform ? waveform.replace(/stroke="[^"]*"/g, 'stroke="#004080"') : '',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLoading && currentTrack && (
        <VideoPlayer
          setProgressPct={setProgressPct}
          src={`https://fm-jacket-uploads.s3.us-west-2.amazonaws.com/${currentTrack.profile_id}/${currentTrack.file}.m3u8`}
          playNext={nextTrack}
          playPrev={prevTrack}
        />
      )}
    </div>
  );
};

export default InfinitePlayer;