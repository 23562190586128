import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';
import DiscordButton from './DiscordButton';
import { FaMicrophone } from "react-icons/fa";
import { IconType } from 'react-icons';
import { TbWorldHeart } from "react-icons/tb";
import { FaMobile } from "react-icons/fa";
import { IoIosVideocam } from "react-icons/io";
import { HiMiniUserGroup } from "react-icons/hi2";
import { PiChatsFill } from "react-icons/pi";
import InfinitePlayer from './InfinitePlayer';

export default function Hero() {

  const renderFeature = (Icon: IconType, heading: string, content: string) => (
    <div className="bg-[#f4f4f9] border-b-4 border-[#001e3baa] rounded-lg shadow hover:shadow-xl transition-all duration-500 p-6 lg:p-8 border flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
      <div className="h-16 w-16 lg:h-20 lg:w-20 bg-[#005599] rounded-full flex items-center justify-center border border-[#003366] shadow-inner">
        <Icon color='white' size={32} />  
      </div>
      <div className="flex-1">
        <h5 className="text-[#2c3e50] font-bold text-xl lg:text-xl mb-3">{heading}</h5>
        <p className="text-[#556575] text-gray-600">{content}</p>
        {/* <span className="font-bold text-[#003366] flex items-baseline">
          View price comparison
          <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
        </span> */}
      </div>
    </div>
  );

  return (
    <main className="bg-[#005599cc] w-full text-gray-700 font-medium">
      <header className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 py-4 lg:py-6 flex justify-between">
        <div>
          <span className="sr-only">Jacket.fm landing page</span>
          <img alt="Jacket.fm logo" src="/favicon.png" className="w-[38px] h-[38px]" />
        </div>
        {/* <nav className="flex items-center space-x-4">
          <a href="#" className="flex space-x-1 items-center hover:text-gray-500"> 
            <svg className="hidden sm:inline w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
            <span>Sign in</span>
          </a>
          <a href="#" className="inline-block bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 font-semibold rounded-lg py-2 px-5 text-white ">Sign up</a>
        </nav> */}
      </header>
    
      <div className="py-12 md:py-24">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 grid md:grid-cols-4 xl:grid-cols-5 gap-x-12 lg:gap-x-20">
          <div className="order-2 md:order-1 col-span-3 self-center mt-12 md:mt-0">
            <h1 style={{ lineHeight: '3.5rem' }} className="text-gray-100 text-3xl md:text-4xl lg:text-5xl font-bold mb-2 md:mb-4 lg:mb-6">Next Generation Blogcasting.</h1>
            <h2 className="text-gray-200 text-xl md:text-2xl lg:text-3xl font-bold mb-1 md:mb-2 lg:mb-4">
              Bring your voice and your phone — we'll do the rest.
            </h2>
            <p style={{ lineHeight: '2rem' }} className="text-lg xl:text-xl text-gray-100 mb-6 lg:mb-8 xl:mb-10">
              Whether it's personal reflections, creative content, or daily updates &mdash; record anytime, anywhere.<br/><br/>Share within your community or keep it private, it's your call.
            </p>
            {/* <div className="flex space-x-4 mb-6">
              <input type="text" placeholder="enter your email..." className="flex-1 py-4 px-4 border border-gray-200 rounded-lg leading-none focus:outline-none" />
              <button className="focus:outline-none inline-block bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 font-semibold rounded-lg py-2 px-5  text-white ">Get started</button>
            </div>
            <p className="text-gray-500 text-sm">No credit card required. Cancel anytime.</p> */}
            {/* <Choice /> */}
            <div className='flex flex-row gap-5 flex-wrap justify-center'>
              <AppStoreButton
                className="mt-5"
                url="https://apps.apple.com/us/app/jacket-fm/id6651823841"
                title="Download on the"
                theme="dark"
                height={60}
              />
              <GooglePlayButton
                className="mt-5"
                url="https://play.google.com/store/apps/details?id=fm.jacket.radio"
                title="Get it on"
                theme="dark"
                height={60}
              />
              <DiscordButton className="mt-5" />
            </div>
          </div>
          <div className="order-2 col-span-3 lg:col-span-2 mt-10 lg:mt-0 mx-auto w-full">
            <InfinitePlayer />
          </div>
        </div>
      </div>

      <div className="bg-[#003366] py-12 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 relative z-20">
          <div className='flex items-center justify-center mb-6'>
            <span className='text-white text-xl uppercase font-semibold mx-auto'>Some of Jacket.fm's best features</span>
          </div>
          <div className="grid md:grid-cols-2 gap-8 lg:gap-12  ">
            {renderFeature(FaMicrophone, 'Record Anything', 'From personal journals to comedy bits—capture any kind of content through voice.')}
            {renderFeature(TbWorldHeart, 'Share Your Way', 'Go public, stay private, or share with select communities. You\'re in control.')}
            {renderFeature(FaMobile, 'Create Anywhere', 'Record, edit, and publish on the go. Your studio is wherever you are.')}
            {renderFeature(IoIosVideocam, 'Video and Text Ready', 'Transform your audio content into transcribed text or shareable videos with just a tap.')}
            {renderFeature(HiMiniUserGroup, 'Build Community', 'Create or join communities around shared interests and content types.')}
            {renderFeature(PiChatsFill, 'Drive Discussion', 'Spark meaningful conversations around your content.')}
          </div>
          <div className='flex items-center justify-center mt-6'>
            <span className='text-white text-xl uppercase font-semibold mx-auto'>...And many others, all 100% free!</span>
          </div>
        </div>
      </div>

      <div className="bg-[#001533] py-12 lg:pt-20 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 grid md:grid-cols-4 xl:grid-cols-5 gap-x-12 lg:gap-x-20">
          <div className="order-2 md:order-1 col-span-2 self-center mt-12 md:mt-0">
            <h1 className="text-gray-100 text-3xl md:text-4xl lg:text-5xl font-bold mb-2 md:mb-4 lg:mb-6">Get Started</h1>
            <h2 className="text-gray-200 text-xl md:text-2xl lg:text-3xl font-bold mb-1 md:mb-2 lg:mb-4">
              Download the app or join us on Discord!
            </h2>
            {/* <p className="text-lg xl:text-xl text-gray-100 mb-6 lg:mb-8 xl:mb-10">
              Treat your ears to short-form audio selected just for you, or upload and share your voice with the world!
            </p> */}
            {/* <div className="flex space-x-4 mb-6">
              <input type="text" placeholder="enter your email..." className="flex-1 py-4 px-4 border border-gray-200 rounded-lg leading-none focus:outline-none" />
              <button className="focus:outline-none inline-block bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 font-semibold rounded-lg py-2 px-5  text-white ">Get started</button>
            </div>
            <p className="text-gray-500 text-sm">No credit card required. Cancel anytime.</p> */}
            {/* <Choice /> */}
            <div className='flex flex-row gap-5 flex-wrap justify-center'>
              <AppStoreButton
                className="mt-5"
                url="https://apps.apple.com/us/app/jacket-fm/id6651823841"
                title="Download on the"
                theme="dark"
                height={60}
              />
              <GooglePlayButton
                className="mt-5"
                url="https://play.google.com/store/apps/details?id=fm.jacket.radio"
                title="Get it on"
                theme="dark"
                height={60}
              />
              <DiscordButton className="mt-5" />
            </div>
          </div>
          <div className="order-2 col-span-2 mt-10 md:mt-0">
            <img src="/iphone_mockup.png" alt="Hero image, phone screenshot" className='w-full md:max-w-[80%] mx-auto md:ml-[20%] md:rotate-3 md:translate-x-36' />
          </div>
        </div>
      </div>

      {/* <div className="bg-[#6e7b8b] py-12 lg:pt-20 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 mb-12 lg:mb-16 xl:mb-24">
          <div className="text-center mb-6 md:mb-8">
            <h2 className="text-gray-800 text-3xl md:text-4xl lg:text-5xl font-bold mb-2 md:mb-4">For Freelancers and Teams</h2>
            <p className="text-lg xl:text-xl text-gray-600">We offer 100% money back guarantee.</p>
          </div>
        </div>
      </div> */}

      <footer className="bg-[#2c3e50] text-gray-600 py-12 xl:pb-24">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 mb-12 lg:mb-16 flex flex-row items-center">
          <img alt="Jacket.fm logo" src="/favicon.png" className="w-[38px] h-[38px]" />
          <span className='text-white ml-3 text-xl font-semibold'>Jacket.fm</span>
        </div>
        {/* <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 grid md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-x-8">
          <div>
            <h5 className="text-xl font-bold text-gray-700">Product</h5>
            <nav className="mt-4">
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Landingpages</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Features</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Showcase</a>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <h5 className="text-xl font-bold text-gray-700">Industries</h5>
            <nav className="mt-4">
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Employment</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Childcare</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Dealerships</a>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <h5 className="text-xl font-bold text-gray-700">About us</h5>
            <nav className="mt-4">
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Company</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Download brochure</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Resources</a>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <h5 className="text-xl font-bold text-gray-700">Legal</h5>
            <nav className="mt-4">
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Terms and conditions</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Security</a>
                </li>
                <li>
                  <a href="#" className="text-base hover:text-gray-500">Privacy</a>
                </li>
              </ul>
            </nav>
          </div>
        </div> */}
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 flex flex-col md:flex-row justify-between items-center space-y-4 mt-16 lg:mt-20">
          <div className="text-sm space-y-4 md:space-y-1 text-center md:text-left text-[#e6e6e6]">
            <p>Email us at: hello@jacket.fm</p>
            <p>&copy;2024 Jacket.fm, Inc.</p>
            <p className='text-gray-400'>Bid me discourse, I will enchant thine ear.</p>
          </div>
          {/* <a href="#" className="inline-block bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 font-semibold rounded-lg py-4 px-5 lg:px-8 text-white md:transform md:-translate-y-2">Start your free trial</a> */}
        </div>
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 flex flex-col md:flex-row justify-between items-center space-y-4 mt-8 lg:mt-12">
          <nav className="flex flex-wrap justify-center space-x-6"> 
            <a href="/privacy" className=" text-sm text-gray-200 hover:text-gray-100 mb-2 underline">Privacy</a>
            <a href="/delete" className=" text-sm text-gray-200 hover:text-gray-100 mb-2 underline">Delete Account</a>
          </nav>
          {/* <nav className="flex items-center space-x-2">
            <a href="#" className="text-gray-500 hover:text-gray-600">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
              </svg>
            </a>
            <a href="#" className="text-gray-500 hover:text-gray-600">
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
              </svg>
            </a>
            <a href="#" className="text-gray-500 hover:text-gray-600">
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </nav> */}
        </div>
      </footer>
    </main>
  );
}
