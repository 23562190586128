import React, { useEffect, useState } from 'react'
import { User } from '@supabase/supabase-js'
import supabase from '../lib/supabase'

const DeleteAccount: React.FC = () => {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null)
    })

    return () => {
      authListener.subscription.unsubscribe()
    }
  }, [])

  const handleGoogleLogin = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin + '/delete'
        }
      })
      
      if (error) throw error
    } catch (error) {
      console.error('Error logging in with Google:', error)
    }
  }

  const handleUserDelete = async () => {
    if(!user) return;
    await supabase.rpc('scrub_user_data', { input_profile_id: user.id });
    alert('Account Deleted');
    await supabase.auth.signOut();
  }

  const handleLogout = async () => {
    await supabase.auth.signOut();
  }

  return (
    <div className='flex flex-col gap-5 text-white items-center justify-center'>
      <h1 className='text-xl font-bold border-b-4'>Account Deletion</h1>
      {user ? (
        <>
        <p>Logged in with {user.email}</p>
        <p>What would you like to do?</p>
        <button className="border-b-4 p-2 px-5 border-confirmButton-border rounded-md overflow-hidden bg-deleteButton-icon" onClick={handleUserDelete}>Delete Account</button>
        <button className="border-b-4 p-2 px-5 border-confirmButton-border rounded-md overflow-hidden bg-confirmButton-background" onClick={handleLogout}>Logout</button>
        </>
      ): (
        <button className="border-b-4 p-2 px-5 border-confirmButton-border rounded-md overflow-hidden bg-confirmButton-background" onClick={handleGoogleLogin}>
          Login with Google
        </button>
      )}
    </div>
  );
}

export default DeleteAccount