import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';
import CompactPlayer from './CompactPlayer';
import DiscordButton from './DiscordButton';

export default function Standalone() {
  return (
    <>
      <CompactPlayer />
      <AppStoreButton
        className="mt-5"
        url="https://apps.apple.com/us/app/jacket-fm/id6651823841"
        title="Download on the"
        theme="dark"
        height={60}
      />
      <GooglePlayButton
        className="mt-5"
        url="https://play.google.com/store/apps/details?id=fm.jacket.radio"
        title="Get it on"
        theme="dark"
        height={60}
      />
      <DiscordButton className='mt-5' />
    </>
  );
}
